import React, { Fragment } from 'react';

const GBIText = () => (
  <Fragment>
    The <span className="brand-good">Good </span>
    <span className="brand-beef">Beef </span>
    <span className="brand-good">Index</span>
  </Fragment>
);

export default GBIText;
