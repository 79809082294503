import React from 'react';
import '../standard-gbi-page.scss';
import PricingInfo from '../../content/infoblocks/PricingInfo';

const PricingGuidelines = () => (
  <div className="bb-page-wrapper standard-gbi-page">
    <h2 className="caption">Pricing Guidelines</h2>
    <PricingInfo />
  </div>
);

export default PricingGuidelines;
