import React, { Fragment } from 'react';
import GBIText from '../components/GBIText';

/* eslint-disable max-len */
const PricingInfo = () => (
  <Fragment>
    <p className="standard-para"><GBIText /> suggests an indicative price structure per Kg of Carcass Weight at each grading level of quality, namely:</p>
    <ul className="standard-bullet">
      <li>Standard</li>
      <li>Superior</li>
      <li>Classic</li>
      <li>Prime</li>
      <li>Supreme</li>
    </ul>
    <div className="intro-divider"></div>
    <p className="standard-para">The pricing applies the average current prices being offered in the market as the price for the Standard grade. Then it applies a % uplift to each grade as a suggested price point for that grade as a farmgate price for a finished animal.</p>
    <p className="standard-para">The % uplift considers the  additional production costs involved to achieve the higher grades – genetic value, 100% grass fed, slower growth, etc. It also draws on customer survey results on beef quality and prices.</p>
    <p className="standard-para" ><span className="highlight">Standard quality – baseline price offered by abattoirs</span></p>
    <p className="standard-para">The standard level of quality is broadly that which is set by the abattoir for an R4L carcass by the EUROP grading EU regulatory regime. This regime does not explicitly recognise eating quality or production or environmental quality. It is based on a visual inspection of “Conformance” (ie Muscle size) and “Fat Cover” (surface fat, excluding subcutaneous or intra-muscular fat).</p>
    <p className="standard-para" ><span className="highlight">Premium quality levels – price uplift to recognise higher quality and production cost</span></p>
    <p className="standard-para">There are 4 premium levels. At each level premium % uplift is applied to the baseline price offered by abattoirs. Good beef that exceeds the standard price level probably represents 10-20% of the overall beef market. Indeed USDA graded beef gives a “Prime” quality grade to only 2-3% of US beef production, which carries a very significant premium. The US prime level of quality broadly aligns with the GoodBeef Index prime level.</p>
    <p className="standard-para" ><span className="highlight">The Voice of the Customer</span></p>
    <p className="standard-para">Consumer survey results indicate that customers are willing to pay double the standard price of beef for a consistently high quality of beef, with its associated taste and succulence attributes. It is also apparent that consumers are increasingly aware of production methods and environmentally friendly farming techniques that influence their buying habits. In short customers want to know what they are buying and why good beef is good for them!</p>

    <p className="standard-para" ><span className="highlight">Price and Cost Transparency</span></p>
    <p className="standard-para">The intention is to provide further details on price determination and cost of production for specified levels of quality.  The GoodBeef Index will also include price-check comparisons with retail outlets. The objective always is open the dialogue on price fairness to inform the consumer.</p>
  </Fragment>
);

export default PricingInfo;
